import React, { useState, useEffect } from "react";
import BoxTypo, { H2, P, H3 } from "../components/BoxTypo";
import { Box, Grid, Button } from "@mui/material";
import collaborations from "../data/plans";
import {
  StaticImage,
  GatsbyImage,
  getImage,
  DynamicImage,
} from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import FGButton from "../components/FGButton";
import queryString from "query-string";
import Plan from "../components/LPPlan";
import "../style/base.css";
import WrapBr from "../components/WrapBr";

const Main = () => {
  const [id, setId] = useState(0);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const tempId = parseInt(queryString.parse(window.location.search)?.id);
      if (
        0 > tempId ||
        9 < tempId ||
        isNaN(tempId) ||
        typeof tempId === `undefined`
      ) {
        setId((_) => 1);
      } else {
        setId((_) => tempId);
      }
    }
  }, []);

  const collaboration = collaborations();
  //リクエストパラメーターから番号を取得。
  //レンジ外の場合はスタッフのおすすめコースにする
  return (
    <>
      <Box bgcolor={"#F4EBDB"}>
        <Box width="90%" maxWidth={"1100px"} m={"auto"}>
          <Granola collaboration={collaboration[id]} id={id} />
        </Box>
      </Box>
    </>
  );
};

const Granola = (props) => {
  const data = useStaticQuery(graphql`
    query {
      g1: file(relativePath: { eq: "TSI.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      g2: file(relativePath: { eq: "SOS.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      g3: file(relativePath: { eq: "TCO.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      g4: file(relativePath: { eq: "SCO.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      g5: file(relativePath: { eq: "TSR.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      g6: file(relativePath: { eq: "CCO.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      g7: file(relativePath: { eq: "SCR.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      g8: file(relativePath: { eq: "TSR.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      g9: file(relativePath: { eq: "SSN.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      g10: file(relativePath: { eq: "NSU.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);
  const { id, collaboration } = props;
  return (
    <>
      <Box width={"100%"} m={"auto"} bgcolor="#fff" py={5} px={2}>
        <Box width={"95%"} maxWidth={"850px"} m={"auto"}>
          <img
            src={"../../FGresult/" + collaboration.img}
            style={{ width: "100%", margin: "auto" }}
          />

          <hr color="#F5EFE6" width="100%" />
          <Grid container spacing={3}>
            {collaboration?.set?.map((c) => {
              return (
                <>
                  <Grid item xs={12} md={6}>
                    <H3
                      color="#A4814B"
                      textAlign="left"
                      m={"32px auto 8px auto"}
                      fw={700}
                    >
                      {c.name}
                    </H3>
                    <P m={0}>{c.description}</P>
                  </Grid>
                </>
              );
            })}
          </Grid>
          <Box width={"100%"} py={5} bgcolor={"#fff"}>
            <hr color="#F5EFE6" width="100%" />
            <Box
              m={"auto"}
              className="mw1100 w100"
              bgcolor={"#fff"}
              textAlign={["center", "center"]}
              py={5}
            >
              <H2 color={"#A4814B"} fw={700}>
                あなたにぴったりの
                <br />
                グラノーラをお届けします
              </H2>
              <Grid
                container
                style={{ marginBottom: "64px", width: "90%", margin: "auto" }}
              >
                <Grid item xs={12} md={7}>
                  <StaticImage
                    alt="img"
                    src="../images/gl2.png"
                    placeholder="blurred"
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <H2
                    fw={"700"}
                    color={"#A4814B"}
                    textAlign={["center", "left"]}
                    m={"32px 0 0 0"}
                  >
                    グラノーラ定期便
                  </H2>
                  <P
                    color={"#A4814B"}
                    m={"0 0 32px 0"}
                    textAlign={["center", "left"]}
                  >
                    270g×2袋
                  </P>
                  <StaticImage
                    alt="img"
                    src="../images/price1.png"
                    placeholder="blurred"
                    style={{ width: "100%", textAlign: "left" }}
                  />
                  <Box width={"95%"} m="auto">
                    <H3 color={"#A4814B"} lh={"180%"} className="tal">
                      お届け頻度は2週に1回 / 1ヶ月に1回 /
                      2ヶ月に1回からお選びいただけます。
                    </H3>
                    <P className="tal" color="#A9A7A1">
                      ※1回目お届け以降マイページからいつでもキャンセル可能です
                      <br />
                      ※単品でもお求めいただけます
                    </P>
                  </Box>
                </Grid>
              </Grid>
              <FGButton text="定期便のご注文" to={collaboration?.url} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Main;
