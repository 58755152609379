import React, { useState } from "react";
import BoxTypo, { H2, P } from "../components/BoxTypo";
import { Box, Grid, Button } from "@mui/material";
import { DotPulse } from "@uiball/loaders";
import {
  StaticImage,
  GatsbyImage,
  getImage,
  DynamicImage,
} from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import "../style/base.css";
import { propsToClassKey } from "@mui/styles";
import WrapBr from "../components/WrapBr";
import AOS from "aos";
import "aos/dist/aos.css";
const Main = (props) => {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
    });
  }

  return (
    <>
      <Box
        sx={{ display: ["block", "block", "none"] }}
        className="w100"
        style={{ height: "100vh" }}
        bgcolor="#F4EBDB"
      >
        <StaticImage
          alt="img"
          src={"../images/result_top_sp.png"}
          placeholder="blurred"
          style={{ width: "100%", height: "100vh", objectFit: "cover" }}
        />
      </Box>
      <Box
        sx={{ display: ["none", "none", "block"] }}
        className="w100"
        style={{ height: "100vh" }}
        bgcolor="#F4EBDB"
      >
        <StaticImage
          alt="img"
          src="../images/result_top_pc.png"
          placeholder="blurred"
          style={{ width: "100%", height: "100vh", objectFit: "cover" }}
        />
      </Box>
      <Box bgcolor="#F4EBDB" m={0}>
        <H2 fw={700} m={"0"} py={[3, 6]}>
          あなたに
          <WrapBr />
          ぴったりの グラノーラは
        </H2>
        <Box width="100%" m={"auto"} textAlign="center" pb={[3, 6]}>
          <Box data-aos="fade-in" data-aos-duration="1000" data-aos-delay="0">
            <P>・</P>
          </Box>
          <Box data-aos="fade-in" data-aos-duration="1000" data-aos-delay="500">
            <P>・</P>
          </Box>
          <Box
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-delay="1000"
          >
            <P>・</P>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Main;
